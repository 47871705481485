import React from "react";
import DefaultLayout from "../../components/DefaultLayout";
import Hero from "../../components/bricks/Hero";
import FeatureOverview from "../../components/FeatureOverview";
import ThemeBox from "../../components/bricks/ThemeBox";
import {themeBright, themeDark} from "../../page-styles/landingpage.css";
import ThemedSingleFeature from "../../components/bricks/ThemedSingleFeature";

const Features = ({location}) => (
  <DefaultLayout
    title="Cards, Decks & Projects"
    description="Well designed collectible card games provide a great sense of the current state of your game. Let's apply those design cues to project management."
    location={location}
  >
    <Hero size="sm" title="Cards, Decks & Projects" />
    <ThemeBox theme={themeBright} withBorder>
      <ThemedSingleFeature childAsDescription title="Why cards?" img="cards-1.png">
        Codecks uses cards for capturing your ideas, goals, tasks... all the things that comprise
        your work-to-be-done. But our cards are inspired by collectible card games, meaning that
        they convey a lot more information through the use of strategically placed icons and values.
      </ThemedSingleFeature>

      <ThemedSingleFeature
        childAsDescription
        title="Cards work for quick tasks…"
        img="cards-2.png"
        imgRight
      >
        Creating a card is as simple as typing a description of your work-to-be done in the card
        body. Because we wanted this to feel like writing thoughts on paper, there are no special
        fields like "title". Just write! We take care of extracting the title from your first
        paragraph of text since that is how you most likely would start writing.
      </ThemedSingleFeature>

      <ThemedSingleFeature
        childAsDescription
        title="…and whole design documents…"
        img="cards-3.png"
      >
        Writing cards is great, but Codecks is not just a "to-do" app. You will find in the card
        header and margin an array of properties and functionality that will bring out the full
        power of a solid project management tool. This ranges from assigning owners and priorities
        to linking cards to a milestone or blocking a card to notify your co-workers of impending
        doom! How many of these extra features you use is entirely up to you.
      </ThemedSingleFeature>

      <ThemedSingleFeature
        childAsDescription
        title="…and they can be personalized, too!"
        img="card-cover-image.png"
        imgRight
      >
        By using cards, we ensure you can personalize your experience as much as you want—after all,
        cards are visual, and ours are customizable, too! You can add your own
        cover images to each card (or deck, but more on that below…), making sure they look as good
        as your game. This both makes the experience of using Codecks more dynamic and also helps
        you immediately identify what each card is about.
      </ThemedSingleFeature>

      <ThemedSingleFeature childAsDescription title="One card – two perspectives" img="cards-4.png">
        Cards have two form-factors. The detailed view is what you see when you create, edit or view
        all the contents of a card. But when just browsing through your cards, they are displayed as
        mini-cards, enabling you to sweep over your work and instantly get a lot of information
        without having to click on them up individually.
      </ThemedSingleFeature>
    </ThemeBox>

    <ThemeBox theme={themeDark} withBorder title="Decks">
      <ThemedSingleFeature childAsDescription img="decks-1.png">
        One of the core tenets of project management is to break down your work in manageable
        chunks. You could use lists or folders but that's not very evocative. Continuing the card
        game analogy, Codecks uses... decks! Choose a cover image that conveys meaning to the cards
        inside, and watch as your decks radiate useful information like card count, notifications or
        number of blocked cards.
      </ThemedSingleFeature>

      <ThemedSingleFeature childAsDescription title="Creating decks" img="decks-2.png" imgRight>
        Adding a deck is very similar to a card - just write, and we'll extract a tile and
        description. But the best part is applying a deck cover image. You can even use an animated
        gif! Organise decks in your library however you like using drag and drop.
      </ThemedSingleFeature>

      <ThemedSingleFeature childAsDescription title="Decks and cards in action" img="decks-3.png">
        Decks are containers for cards. Click on the deck to open the inspector, revealing all the
        cards that are inside. You can freely move cards between decks using drag and drop, but we
        offer other ways to do that, especially for moving multiple cards.
      </ThemedSingleFeature>

      <ThemedSingleFeature childAsDescription title="Managing your deck" img="decks-4.png" imgRight>
        The header of the deck inspector is where you will find actions for renaming, deleting,
        subscribing or applying a milestone to the entire deck.
      </ThemedSingleFeature>
    </ThemeBox>

    <ThemeBox theme={themeBright} withBorder title="Projects">
      <ThemedSingleFeature childAsDescription img="projects-1.png">
        Most teams need to tackle several projects at the same time. Codecks fully embraces this
        with multi-project support. Whether you want to focus on a single project a day or need to
        get a full overview. We've got you covered.
      </ThemedSingleFeature>

      <ThemedSingleFeature childAsDescription title="Zoom in or out" img="projects-2.png" imgRight>
        The project picker allows you to quickly select only those projects that you need to work on
        at any given time. This way you'll see cards and decks for these projects only. Feel free to
        drag and drop them around: every team member get's to pick their individual order.
      </ThemedSingleFeature>

      <ThemedSingleFeature
        childAsDescription
        title="Access management via projects"
        img="projects-3.png"
      >
        You need to invite a freelancer and don't want them to see everything you're working on?
        Need a special place that's only accessible for the admins? Projects are Codecks' way to
        define who has access to what content.
      </ThemedSingleFeature>
    </ThemeBox>
    <FeatureOverview />
  </DefaultLayout>
);

export default Features;
